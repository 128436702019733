import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  Card,
  CardDesctiptionContainer,
  CardHead,
  CardsContainer,
  MainContainer,
  InfoCardContainer,
  WhiteText as WhiteTextSmall,
  CardWrapper,
  CenterSection,
  CenterLogo,
  LogoBackground1,
  LogoBackground2,
  LogoBackground3,
  Lines,
} from "./styled";

import { SoonReleased } from "components/Common/styled";

import logo from "assets/logo.svg";
import linesImage from "assets/Foxify_Liney_With_Icons_Bigger_2.svg";

const FoxifyProtocoll = () => {
  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>Foxify Protocol</SectionHeader>
        <div>The best of DeFi meets a professional trading experience</div>
      </DescriptionContainer>
      <CardsContainer>
        <InfoCardContainer>
          <CardWrapper>
            <Card>
              <CardHead>
                <WhiteTextSmall>Trade</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                  Trade USDC isolated margined perpetual contracts with up to
                  50x Leverage and instant settlement. 
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <WhiteTextSmall>Get Funded</WhiteTextSmall>
                <SoonReleased>Soon</SoonReleased>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                  After successful completion of the 7-day challenge, you will
                  be instantly funded with a{" "}
                  <strong>max trade of $50,000</strong>.
                </Description>
              </CardDesctiptionContainer>
            </Card>
          </CardWrapper>
          <CenterSection>
            <Lines src={linesImage} />
            <LogoBackground2></LogoBackground2>
            <LogoBackground3></LogoBackground3>
            <LogoBackground1>
              <CenterLogo src={logo} />
            </LogoBackground1>
          </CenterSection>

          <CardWrapper>
            <Card>
              <CardHead>
                <WhiteTextSmall>Earn</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                  Stake $Fox tokens to participate in Foxify‘s revenue sharing
                  program and earn real yield instantly. 
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <WhiteTextSmall>Trustless</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                  Trade without middlemen! Our platform is powered by smart
                  contracts, so you stay in control.
                </Description>
              </CardDesctiptionContainer>
            </Card>
          </CardWrapper>
        </InfoCardContainer>
      </CardsContainer>
    </MainContainer>
  );
};

export default FoxifyProtocoll;
