import {
  Check,
  CheckIconContainer,
  Container,
  Divider,
  FundedLevel,
  FundedLevelDetail,
  FundedLevelDetails,
  FundedLevelLeverage,
  FundedLevelMaxTradeSize,
  FundedLevelRank,
  FundedLevels,
  StyledLinkButton,
  Title,
} from "./styled";
import spikeSvg from "assets/spike.svg";

export default function FundingLevels() {
  return (
    <Container>
      <Title>Funding Levels</Title>
      <FundedLevels>
        <FundedLevel>
          <FundedLevelRank>
            <img src={spikeSvg} alt="spike" />
            Qualification
          </FundedLevelRank>
          <FundedLevelMaxTradeSize>$20,000</FundedLevelMaxTradeSize>
          <FundedLevelLeverage>Max Trade (50x Leverage)</FundedLevelLeverage>
          <Divider />
          <FundedLevelDetails>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Collateral: <strong>$400</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Timeline: <strong>7 days</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              PnL Target: <strong>15%</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Minimum Trades: <strong>8</strong>
            </FundedLevelDetail>
          </FundedLevelDetails>
          <StyledLinkButton
            href="https://perp.foxify.trade/#/funded/start"
            target="_blank"
          >
            Start your challenge
          </StyledLinkButton>
        </FundedLevel>
        <FundedLevel>
          <FundedLevelRank>
            <img src={spikeSvg} alt="spike" />
            Silver
          </FundedLevelRank>
          <FundedLevelMaxTradeSize>$50,000</FundedLevelMaxTradeSize>
          <FundedLevelLeverage>Max Trade (50x Leverage)</FundedLevelLeverage>
          <Divider />
          <FundedLevelDetails>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Collateral: <strong>$2,000</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Timeline: <strong>15 days</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              PnL Target: <strong>15%</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Minimum Trades: <strong>16</strong>
            </FundedLevelDetail>
          </FundedLevelDetails>
          <StyledLinkButton
            href="https://perp.foxify.trade/#/funded/start"
            target="_blank"
          >
            Start your challenge
          </StyledLinkButton>
        </FundedLevel>
        <FundedLevel>
          <FundedLevelRank>
            <img src={spikeSvg} alt="spike" />
            Gold
          </FundedLevelRank>
          <FundedLevelMaxTradeSize>$100,000</FundedLevelMaxTradeSize>
          <FundedLevelLeverage>Max Trade (50x Leverage)</FundedLevelLeverage>
          <Divider />
          <FundedLevelDetails>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Collateral: <strong>$5,000</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Timeline: <strong>20 days</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              PnL Target: <strong>15%</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Minimum Trades: <strong>24</strong>
            </FundedLevelDetail>
          </FundedLevelDetails>
          <StyledLinkButton
            href="https://perp.foxify.trade/#/funded/start"
            target="_blank"
          >
            Start your challenge
          </StyledLinkButton>
        </FundedLevel>
        <FundedLevel>
          <FundedLevelRank>
            <img src={spikeSvg} alt="spike" />
            Platinum
          </FundedLevelRank>
          <FundedLevelMaxTradeSize>$250,000</FundedLevelMaxTradeSize>
          <FundedLevelLeverage>Max Trade (50x Leverage)</FundedLevelLeverage>
          <Divider />
          <FundedLevelDetails>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Collateral: <strong>$10,000</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Timeline: <strong>25 days</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              PnL Target: <strong>15%</strong>
            </FundedLevelDetail>
            <FundedLevelDetail>
              <CheckIconContainer>
                <Check />
              </CheckIconContainer>
              Minimum Trades: <strong>30</strong>
            </FundedLevelDetail>
          </FundedLevelDetails>
          <StyledLinkButton
            href="https://perp.foxify.trade/#/funded/start"
            target="_blank"
          >
            Start your challenge
          </StyledLinkButton>
        </FundedLevel>
      </FundedLevels>
    </Container>
  );
}
