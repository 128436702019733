import circleBitcoinImage from "assets/circle-bitcoin.png";
import profitChartImage from "assets/profit-chart.png";
import bitcoinFiatImage from "assets/bitcoin-fiat.png";
import drawDownImage from "assets/draw-down.png";
import depositImage from "assets/deposit.png";
import qualifyImage from "assets/qualify-box.png";

import {
  Container,
  CardsContainer,
  Card,
  Title,
  Description,
  ImageContainer,
  Image,
  SectionTitle,
  QualifySeciton,
  HowToQualifyImageContainer,
  HowToQualifyImage,
  QualifyStep,
  QualifyStepTitle,
  QualifyStepDescription,
  StepNumber,
  VerticalLine,
  QualfiySteps,
} from "./styled";
import { Box } from "@mui/material";
import { colors } from "config/colors";

export default function Funded() {
  return (
    <Container>
      <CardsContainer>
        <Card>
          <ImageContainer>
            <Image src={circleBitcoinImage} alt="bitcoin" />
          </ImageContainer>
          <Title>Funded within 7 days</Title>
          <Description>
            Trade for 7 days with at least $400 collateral & meet KPIs to
            qualify. Retain 100% of your profits.
          </Description>
        </Card>
        <Card>
          <ImageContainer>
            <Image src={profitChartImage} alt="profit-chart" />
          </ImageContainer>
          <Title>80% profit split</Title>
          <Description>
            Earn 80% of the profits you generate from your funded account.
          </Description>
        </Card>
        <Card>
          <ImageContainer>
            <Image src={bitcoinFiatImage} alt="bitcoin-fiat" />
          </ImageContainer>
          <Title>No Paper Trading</Title>
          <Description>
            Real trading from day 1, trade top  crypto pairs verifiable
            on-chain.
          </Description>
        </Card>
        <Card>
          <ImageContainer>
            <Image src={drawDownImage} alt="drawdown" />
          </ImageContainer>
          <Title>No Drawdown Limit</Title>
          <Description>
            Dedicated to your success, we allow you to trade without drawdown
            limits.
          </Description>
        </Card>
        <Card>
          <ImageContainer>
            <Image src={depositImage} alt="deposit" />
          </ImageContainer>
          <Title>No Deposit</Title>
          <Description>
            Unlike other traditional prop firms, Foxify does not charge any
            application or one-time fees.
          </Description>
        </Card>
      </CardsContainer>
      <Box>
        <SectionTitle>How to qualify</SectionTitle>
        <QualifySeciton>
          <HowToQualifyImageContainer>
            <HowToQualifyImage src={qualifyImage} />
          </HowToQualifyImageContainer>
          <QualfiySteps>
            <QualifyStep>
              <StepNumber>
                01
                <VerticalLine />
              </StepNumber>
              <Box>
                <QualifyStepTitle>Grow your account</QualifyStepTitle>
                <QualifyStepDescription>
                  Start with $400 USDC, trade while meeting performance targets,
                  to qualify for a funded account. 100% of the profits are yours
                  to keep during this period.‍
                </QualifyStepDescription>
              </Box>
            </QualifyStep>
            <QualifyStep>
              <StepNumber>
                02
                <VerticalLine />
              </StepNumber>
              <Box>
                <QualifyStepTitle>Performance criteria</QualifyStepTitle>
                <QualifyStepDescription>
                  Grow your balance +15% in 7 days, meet the KPI's and get
                  FUNDED!
                </QualifyStepDescription>
              </Box>
            </QualifyStep>
            <QualifyStep>
              <StepNumber>
                03
                <VerticalLine />
              </StepNumber>
              <Box>
                <QualifyStepTitle style={{ color: colors.main }}>
                  Get FUNDED!
                </QualifyStepTitle>
                <QualifyStepDescription>
                  After successful completion of the 7 day challenge, you will
                  be instantly funded with a max trade of $50,000. Keep
                  performing to grow your account further. Keep 80% of net
                  profits at the end of each challenge successfully completed.
                </QualifyStepDescription>
              </Box>
            </QualifyStep>
          </QualfiySteps>
        </QualifySeciton>
      </Box>
    </Container>
  );
}
